// Here you can add other styles
.card,
.dropdown-menu,
.footer,
.header,
.sidebar {
  box-shadow: rgb(50 50 93 / 2%) 0 2px 5px -1px, rgb(0 0 0 / 5%) 0 1px 3px -1px;
}

.sidebar-overlaid {
  box-shadow: rgb(50 50 93 / 2%) 0 2px 5px -1px, rgb(0 0 0 / 5%) 0 1px 3px -1px, 0 5px 6px -3px rgb(0 0 0 / 25%);
}

.card-header {
  border-bottom: var(--cui-card-border-width) solid var(--cui-card-border-color);
}

.footer,
.header {
  border: 0;
}

.sidebar-nav {
  margin: .75rem;

  .nav-link {
    @include border-radius($border-radius);
  }

  .nav-group.show .nav-group-toggle:hover {
    color: $high-emphasis-inverse;
  }
}

.sidebar-narrow-unfoldable:not(:hover) .sidebar-nav {
  margin: .75rem 0;
}

.sidebar-brand {
  justify-content: space-between;
  padding: 0 $sidebar-nav-link-padding-x * .5;
}

.sidebar-toggler {
  flex: 0 0 2rem;
  background-color: transparent;
  filter: invert(1) grayscale(100%) brightness(200%);

  &::before {
    width: 2rem;
  }

  &:hover {
    background-color: transparent;
  }
}

.sidebar-narrow-unfoldable:not(:hover) {
  .sidebar-brand {
    justify-content: center;
    padding: 0;
  }
  .sidebar-toggler {
    display: none;
  }
}

@if $enable-dark-mode {
  @include color-mode(dark) {
    .sidebar {
      background-color: var(--cui-body-bg) !important; // stylelint-disable-line
      background-image: none !important; // stylelint-disable-line
      border: 0;
    }

    .nav-group-toggle::after{
      filter: invert(1) grayscale(100%) brightness(200%);
    }
  }
}
