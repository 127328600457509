// stylelint-disable scss/dollar-variable-default
$white:     #fff;
$gray-base: #3c4b64;
$gray-100:  #f0f4f7;
$gray-200:  #ebedef;
$gray-300:  #d8dbe0;
$gray-400:  #c4c9d0;
$gray-500:  #b1b7c1;
$gray-600:  #9da5b1;
$gray-700:  #8a93a2;
$gray-800:  #768192;
$gray-900:  #636f83;
$black:     #000015;

$primary:   #5046e5;
$secondary: #ebedef;
$success:   #51cc8a;
$danger:    #ef376e;
$warning:   #fc0;
$info:      #747af2;
$light:     $gray-100;

$primary-text-emphasis:   shade-color($primary, 60%) !default;
$secondary-text-emphasis: shade-color($secondary, 60%) !default;
$success-text-emphasis:   shade-color($success, 60%) !default;
$info-text-emphasis:      shade-color($info, 60%) !default;
$warning-text-emphasis:   shade-color($warning, 60%) !default;
$danger-text-emphasis:    shade-color($danger, 60%) !default;
$light-text-emphasis:     $gray-700 !default;
$dark-text-emphasis:      $gray-700 !default;

$primary-bg-subtle:       tint-color($primary, 80%) !default;
$secondary-bg-subtle:     tint-color($secondary, 80%) !default;
$success-bg-subtle:       tint-color($success, 80%) !default;
$info-bg-subtle:          tint-color($info, 80%) !default;
$warning-bg-subtle:       tint-color($warning, 80%) !default;
$danger-bg-subtle:        tint-color($danger, 80%) !default;
$light-bg-subtle:         mix($gray-100, $white) !default;
$dark-bg-subtle:          $gray-400 !default;

$primary-border-subtle:   tint-color($primary, 60%) !default;
$secondary-border-subtle: tint-color($secondary, 60%) !default;
$success-border-subtle:   tint-color($success, 60%) !default;
$info-border-subtle:      tint-color($info, 60%) !default;
$warning-border-subtle:   tint-color($warning, 60%) !default;
$danger-border-subtle:    tint-color($danger, 60%) !default;
$light-border-subtle:     $gray-200 !default;
$dark-border-subtle:      $gray-500 !default;

$primary-gradient: (
  "start":  tint-color($primary, 30%),
  "stop":   shade-color($primary, 10%)
) !default;

$danger-gradient: (
  "start":  tint-color($danger, 30%),
  "stop":   shade-color($danger, 10%)
) !default;

$warning-gradient: (
  "start":  tint-color($warning, 30%),
  "stop":   shade-color($warning, 10%)
) !default;

$success-gradient: (
  "start":  tint-color($success, 30%),
  "stop":   shade-color($success, 10%)
) !default;

$info-gradient: (
  "start":  tint-color($info, 30%),
  "stop":   shade-color($info, 10%)
) !default;

$enable-elevations: false !default;
$font-size-base: .9375rem !default;
$border-color-translucent:  rgba($black, .0875) !default;

$footer-bg:  var(--cui-body-bg) !default;

$header-color:                var(--cui-medium-emphasis-inverse) !default;
$header-hover-color:          var(--cui-high-emphasis-inverse) !default;
$header-active-color:         var(--cui-high-emphasis-inverse) !default;
$header-toggler-color:        var(--cui-medium-emphasis-inverse) !default;
$header-toggler-hover-color:  var(--cui-high-emphasis-inverse) !default;

$sidebar-width:  18rem;
$sidebar-widths: (
  sm: 14rem,
  lg: 24rem,
  xl: 26rem
);

$sidebar-brand-bg:             rgba($black, .15) !default;

$sidebar-nav-link-padding-y:   .75rem !default;
$sidebar-nav-link-padding-x:   1.5rem !default;
$sidebar-nav-link-hover-bg:    rgba($black, .05) !default;
$sidebar-nav-link-active-bg:   rgba($black, .05) !default;
$sidebar-nav-link-icon-color:  var(--cui-disabled-inverse) !default;

$sidebar-light-nav-link-active-icon-color:  var(--cui-primary) !default;
$sidebar-light-nav-link-hover-bg:           var(--cui-primary) !default;
$sidebar-light-nav-link-hover-color:        var(--cui-high-emphasis-inverse) !default;
$sidebar-light-nav-link-hover-icon-color:   var(--cui-high-emphasis-inverse) !default;

$table-cell-padding-y:  .75rem !default;
$table-cell-padding-x:  .75rem !default;
